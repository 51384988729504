@import url("https://fonts.googleapis.com/css2?family=Anta&display=swap");

body {
  margin: 0;
  font-family: "Anta", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 100vw;
  min-height: 100vh;
  background: url("./assets/background.jpg") no-repeat center center fixed;
  background-size: cover;
  backdrop-filter: blur(60px);
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
}

::selection {
  background-color: #ffcc00; /* Change to the color you want */
  color: #000000; /* Optionally change the text color */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
